function businessHoursDisplay() {
  var today = new Date(),
    stringToday = today.toString(),
    dd = String(today.getDate()).padStart(2, "0"),
    mm = String(today.getMonth() + 1).padStart(2, "0"), //January is 0!
    yyyy = today.getFullYear(),
    wdNum = today.getDay(),
    isClosed = false;
  var morningHours = new Array(7);
  //CHANGE MORNING HOURS HERE - MANUAL
  morningHours[0] = "-";
  morningHours[1] = "08<sup>00</sup> - 12<sup>30</sup>";
  morningHours[2] = "08<sup>00</sup> - 12<sup>30</sup>";
  morningHours[3] = "08<sup>00</sup> - 12<sup>30</sup>";
  morningHours[4] = "08<sup>00</sup> - 12<sup>30</sup>";
  morningHours[5] = "-";
  morningHours[6] = "-";
  var afternoonHours = new Array(7);
  //CHANGE AFTERNOON HOURS HERE - MANUAL
  afternoonHours[0] = "-";
  afternoonHours[1] = "14<sup>30</sup> - 18<sup>30</sup>";
  afternoonHours[2] = "14<sup>30</sup> - 19<sup>00</sup>";
  afternoonHours[3] = "-";
  afternoonHours[4] = "14<sup>30</sup> - 19<sup>00</sup>";
  afternoonHours[5] = "14<sup>30</sup> - 17<sup>00</sup>";
  afternoonHours[6] = "-";
  var mh = morningHours[today.getDay()],
    ah = afternoonHours[today.getDay()];
  var elementLanguage = document.getElementById("hours").getAttribute("data-language");
  //closes practice on weekends
  if (wdNum == "0" || wdNum == "6") {
    var isClosed = true;
  }
  //displays weekday and date of current day
  //english text version
  if (elementLanguage == "en") {
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    var wd = weekday[today.getDay()];
    today = wd + ", " + dd + "." + mm + "." + yyyy;
    document.getElementById("day_and_date").innerHTML = today;
    if (isClosed === true) {
      document.getElementById("day_and_date").innerHTML = today + " (Closed)";
    }
  }
  //german text version
  else {
    var weekday = new Array(7);
    weekday[0] = "Sonntag";
    weekday[1] = "Montag";
    weekday[2] = "Dienstag";
    weekday[3] = "Mittwoch";
    weekday[4] = "Donnerstag";
    weekday[5] = "Freitag";
    weekday[6] = "Samstag";
    var wd = weekday[today.getDay()];
    today = wd + ", der " + dd + "." + mm + "." + yyyy;
    document.getElementById("day_and_date").innerHTML = today;
    if (isClosed === true) {
      document.getElementById("day_and_date").innerHTML = today + " (Geschlossen)";
    }
  }
  //displays working hours of current day
  document.getElementById("morning_hours").innerHTML = mh;
  document.getElementById("afternoon_hours").innerHTML = ah;
  if (isClosed === true) {
    document.getElementById("morning_hours").innerHTML = "-";
    document.getElementById("afternoon_hours").innerHTML = "-";
  }
  //ADD NEW VACATION DATES HERE
  //displays vacation days
  var vacationText = "";
  //INSERT TEXT HERE - MANUAL
  vacationText = "<li>18.05.2023 – 29.05.2023</li> <li>09.06.2023</li> <li>17.07.2023 – 30.07.2023</li> <li>16.10.2023 – 22.10.2023</li> <li>25.12.2023 – 31.12.2023</li>";

  document.getElementById("vacation_hours").innerHTML = vacationText;
  //CHANGE DATES HERE - manual
  //closes practice on vacation days
  var stringDateFirstFifteen = stringToday.substring(4, 15);
  if (
    [
      "May 18 2023",
      "May 19 2023",
      "May 20 2023",
      "May 21 2023",
      "May 22 2023",
      "May 23 2023",
      "May 24 2023",
      "May 25 2023",
      "May 26 2023",
      "May 27 2023",
      "May 28 2023",
      "May 29 2023",
      "Jun 09 2023",
      "Jul 17 2023",
      "Jul 18 2023",
      "Jul 19 2023",
      "Jul 20 2023",
      "Jul 21 2023",
      "Jul 22 2023",
      "Jul 23 2023",
      "Jul 24 2023",
      "Jul 25 2023",
      "Jul 26 2023",
      "Jul 27 2023",
      "Jul 28 2023",
      "Jul 29 2023",
      "Jul 30 2023",
      "Oct 16 2023",
      "Oct 17 2023",
      "Oct 18 2023",
      "Oct 19 2023",
      "Oct 20 2023",
      "Oct 21 2023",
      "Oct 22 2023",
      "Dec 25 2023",
      "Dec 26 2023",
      "Dec 27 2023",
      "Dec 28 2023",
      "Dec 29 2023",
      "Dec 30 2023",
      "Dec 31 2023",
    ].indexOf(stringDateFirstFifteen) >= 0
  ) {
    var isClosed = true;
  }
}
//call today widget only on pages containing it
var hoursElement = document.getElementById("hours");
if (typeof hoursElement != "undefined" && hoursElement != null) {
  businessHoursDisplay();
}
